export default `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve">  <image id="image0" width="96" height="96" x="0" y="0"
    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAA7DAAAOwwHHb6hkAAAJyklEQVR42u2be3BU1RnAf9/ZJCSRV8iDCsUkEgLGKj6RAeua
BFERX8jiA9S+xk59dOxfnek/iKP+43Rqx1YdbZlKjQxBra0oKIWRUnCUUhSU4RFMqAaBhGiWITyS
3K9/LLssye7m3s0+onN+Mwx37z2P73HO+c757g1YLBaLxWKxWCwWi8VisVgsFovFYrFYLBaLxWKx
WCyW7xoymMqBQCCvo6NjhqrWAxcBk4GxwHAgN2aHIrXr1q17P1MKOvdUlNPNVYhcClwAjAcZD+SL
UJTANLWyYl/a5cxJplJtbe1kEXnoyJEjiyCREjEM4jiLgbQq5iyomApmETBXepmC8d6GavrlBI8z
YNasWeN6e3ufAhaJiC/pTtMwC3QxRndVLATzsMC0FJkn7bPA9diora29x3GcT0Xk/sEYHyKzIGU4
d1bO1V2VnwhmWeqMH5kFaWXAGRAIBHxHjhx5GvhVivver6rvi8jrnZ2da7Zu3drttQFn/vfH4Mt7
XmBBugykaA8qRxH9EmQPoptwZK1p/PzTVLSf0AGBQMDX3t7+sogsTJeCAKraKiJPFxcXP79y5cpT
buo48yv8GPOqCOPSKVtcmdEdODwjFLwiK3e6kjkWCR1QV1f3HPCLjCmlukNEFq1fv357onJOoGIB
RpYJMixTssWVGW1C9WHT2PJuMvXjxoC6urqfkUHjA4jIRcCm2tra2fHKOHdVPoSY5UPB+ACCVCFm
tbOg8nFNYlsfs0J9fX21qn4C5GdDKVXtchzHv2HDhv9E33cWVC5E5K8yyPNLGuX+Oyfy7jX/2H3U
bR0Tp6EXyZLxAUSk0OfzvTJ9+vSCiEyB869GWDpUjX9a7lvJP/WmBmry3Nbp54D6+vq5gD/bygCT
CwoKngJwFp1fpqKvC+JasWwhInVqTix1uxz1c4CqPpZtJaKU+bnf7y/hlL4gImXZlse13LBQ76x0
tW0/ywF1dXXTgMvTJVh5seG+Gbn8+ccF3Dcj102Vgmn5J58XkdvTarG0IE848ydWDVSqby7op6kW
o7zY4J/swz85h4qSM/6uKAmtJss2Jz5/HejJ+RYaHwQK8OmfFGoFNEG5EIsXLzYbN25sBb432M7j
GT0WL286NaATlhd/SZnpyawFR+bBVefC/iDs+TrpZtRx7jQrWxrjPY84wO/3X+Hz+bYk25EXo/dl
ICf8ekQbs/OPJW0ET+QamFoKNcVgBByFNc1w+HhSzSm6U6Y0XyRLcGI9jyxBPp9vutfGB2P0aO6f
mXg52tOTz2zS7AABqorgslIoiIpPRuDaCbCqGbo8p6sQpEZ3Vd4Fza/Geh5xgKpeLjLwzilVRu9L
Iicc7B1U8nVgSgth2tjQ/7EozA05YU1zaEZ4Rh4FEjsAmBiverqM3pd4TjjYm9R7o4EpyA2N+Kqi
gXftZQUw/VzYfMBzNwJXaqD8Elm5/+O+z6I1K49+kCmj9yWWE4KkeAYYCa3xU0tDa75bqoug/XhS
QVnFPAA82E+UqOtR0Q+W/qSA+2fmZdT4YcJOCHPCSWH2YfxwuK0Krhjrzfhhpp8bmg3eCWig/0iK
lmB46rRMLcc1BQ4YmQfXlYf+jRxERiMclAtdHSQjiEiJSsXMfs1FFUhzpEseZzD5t1wTGu23VYVG
fyoIB2XjVS5za787KbTT0EKASUUwrwp+UJKEsQYgHJQ9odf3vfPddEBpIcyphJnjzt7Tp5pqT1/k
gEiN3l1dEn0rTfu7LOFlW5kFBESdnmuAN8L3DICzfOKEkcOc5M7aGWBcYQ8MT2BRI6FlZl5VaNkZ
gsaPoHpWxsE4yydOQM3HL91xqKCq2PtRO91cUHaKZ+9oA7+J/Y5usNvKzFMT/SMHfL8TYUzJOb08
c/Nhntx4Hh/s641be3dLJ2+sbQHgjusqqK4YhRe81L96ko/fXN3GsBwFBC428FGfnNZ15YPuJx31
4yMXRv8y6JnIXJCrPH57PrddFj9w/e2fLQSPdRM81s0b6/Z77t5t/XmX57L41vzTxj9NmfsRnik5
PSOUa6Amsh/up5EReKQ++69eH6rLS/nOcSggIKrHI8uQQWStlwbmzapg1Ig8Ro3IY96sCs8CDKr+
Icd10azKOQDik4gDRBuqp6jRzYKENrWjqxk62wiFb/aELk8prO+FE32K/OhCz62mlL985l0r1cdN
Y/NiACML9+wCZ6qirykE1dFkEt7pQR3oBlqd2Mb/9hJ57ZsDYO7e9wUQAHBWT94GXJJtCQE40g1v
Z/hdcGYoDV/031YoO7MtXYTO786QPxuJfNEda1/372yLF+FwV7YlSBMayQfFcIDvvWyLF5IROJCh
LyEyjTA6fNn/HDDnzX2qfJBtGTncBUeT/ruHIY0gkSRo7KOl0T9kW0h2dWRbgrShMDJ8HdMBMuxo
o6ruzpqEnSehOeiqaFd79uJEMn2rahDVe8O/YzugdkMP8MusafbBVyT4nPIsmt7ZmzUx967a46m8
qm4WcaaaxuaG8L242S0zZ9V7Ci9lXKvdHXDQffDt2NfBV1u/yriYB7a08nWzu89TFO0BXSKHzvPL
iv0t0c8SphelWx9V9MNMKaWqH+l/Dz2qqu1e6jWt3kuw1d2SlQqCrUGa1jS51IkWMH5Z0fyYbNjQ
71SZ2AG3rOpCe27ORDwI9dEz1yxr+r34ci9QeC40cgamt7uXHQ3bMxIPutq72NGwHadn4MSgQgO5
ZqpZsW9zvDKusm7OOzeUIrlvC1yZDqUUtqDdN5k5a9rO6nd+VQ2m97cickO8uu83H45c556Ty8UL
L2bE+JGkg6OtQbY3bKf72Jk3h9dW9v/DHVUNAg9Gr/XxcPWGw8xZ0yb5wWsUfTHVSqnyguQHr+lr
fADzWtNO09h8o4rcqOiAKZLuY91sW7qNA1u9f785EAe2HGDb0m1nGT+2Pv0DbSI855119S2zVPVZ
EaYMRiFFd+LII+amt9a7Ku/35+jY/z0ALBGRyFE+egZEUzRxDJNunERhvC+eXXKs7RhNb++NG3DD
M0DRHoEnOXjeE7HW+ngklfjXxoBPh58MIM7DqMwQcdeOKoqwCZU/yoeXNsqSJe7fsITbuLu6RJ2e
JaAPCJITzwEAIkLphWWMv2o8oyaMcq+tQucXnbR+2ErbZ4fRBBn6ayvLQoFWZGGitT6ujJ6t3wfn
3ZsrcXQ2yA9FmaJoBcKI080HRdmvwi5R/qU+1prr32oebJ9wJj5saGm7wU35/KJ8xkwcw6jy0RSW
FJI/Oh/fsNDXmL0neznxzQm62rvobPmGjs87OPG1u0ysv7KsgRzzoGloytw2zGKxWCwWi8VisVgs
FovFYrFYLBaLxWKxWCwWi2XI83+KS3Mjl83w3wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wMy0x
M1QxNjo1NTo1NyswMzowMOxMIr4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDMtMTNUMTY6NTU6
NTcrMDM6MDCdEZoCAAAAAElFTkSuQmCC" />
</svg>`
